import React from 'react';
import styled from '@emotion/styled';

import ArrowNext from '../../assets/arrow-next.svg';
import ArrowPrevious from '../../assets/arrow-previous.svg';
import ArrowDown from '../../assets/arrow-down.svg';
import ArrowUp from '../../assets/arrow-up.svg';
import ShareIcon from '../../assets/icon-cta-share.svg';
import OpenLinkIcon from '../../assets/icon-open-link.svg';
import FilterIcon from '../../assets/icons-cta-filter.svg';
import BurgerIcon from '../../assets/burger-menu.svg';
import CloseIcon from '../../assets/icon-cta-close.svg';
import ActionsIcon from '../../assets/icon-cta-actions.svg';
import PlusIcon from '../../assets/plus.svg';
import MinusIcon from '../../assets/minus.svg';
import ArrowUpCircle from '../../assets/arrow-up-circle.svg';

const icons = {
  'down-arrow': <ArrowDown />,
  'up-arrow': <ArrowUp />,
  'right-arrow': <ArrowNext />,
  'left-arrow': <ArrowPrevious />,
  share: <ShareIcon />,
  'open-link': <OpenLinkIcon />,
  filter: <FilterIcon />,
  burger: <BurgerIcon />,
  actions: <ActionsIcon />,
  close: <CloseIcon />,
  plus: <PlusIcon />,
  minus: <MinusIcon />,
  'arrow-up-circle': <ArrowUpCircle />,
};

export type IconProps = {
  kind: keyof typeof icons;
  strokeColor: string;
  fillColor?: string;
};

export const Icon: React.FC<IconProps> = ({ kind, strokeColor, fillColor }) => {
  const Wrapper = styled.i`
    svg {
      display: block;
      stroke: ${strokeColor};
      fill: ${fillColor};
    }
  `;

  return <Wrapper aria-hidden="true">{icons[kind]}</Wrapper>;
};
